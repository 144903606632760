import React, { useContext } from "react";
import styled from 'styled-components'
import contentFetcher from "data/datafetchers/contentFetcher";
import SiteContext from "helpers/SiteContext";
import theme from "styles/theme";
import { Text } from "components/common";
import { stringDay, getTime } from "helpers/time";

interface Scheduled {
    title: string
    date: string
    start: string
    end: string
}

const styles = {
    titleText: {
        fontWeight: theme.fontRegular,
        color: theme.dark,
        marginTop: 40, 
    },
    scheduledTitle: {
        fontWeight: theme.fontRegular,
        color: theme.dark,
        textAlign: 'left',
    },
    scheduledTime: {
        fontWeight: theme.fontMedium,
        color: theme.dark,
        textAlign: 'left',
    },
    dayTitle: {
        fontWeight: theme.fontMedium,
        color: theme.dark,
        textAlign: 'left',
    }
}

const RushSchedule = () => {
    const { isMobile } = useContext(SiteContext);
    const rush = contentFetcher('current', true);

    const events = Object.keys(rush.events).map(k => {
        const event = rush.events[k]
        return {
            'title': k,
            'date': event.date,
            'start': event.start,
            'end': event.end
        }
    })

    const days = events.reduce((arr: any[], e) => {
        if (arr[e.date]) {
            arr[e.date].push(e);
        } else {
            arr[e.date] = [e]
        }
        return arr;
    }, []);

    const rows = Object.keys(days).map(d => {
        return <DayRow key={d} day={d} events={days[d]}/>
    })
    
    return (
        <SectionContainer margin={15}>
            <Text size={isMobile ? theme.fontL : theme.fontXXL} style={styles.titleText}>{rush.year} CPW Schedule</Text>
            {rows}
        </SectionContainer>
    )
}

const DayRow = (props: {
    day: string;
    events: Scheduled[];
}) => {
    const { isMobile } = useContext(SiteContext);
    const dayString = stringDay(props.day, !isMobile);
    const sortedEvents = props.events.sort((a, b) => parseInt(a.start) - parseInt(b.start));
    const eventSections = sortedEvents.map(e => <ScheduledEvent key={e.title} event={e}/>)

    return (
        <DayContainer>
            <Text size={isMobile ? theme.fontM : theme.fontL} style={styles.dayTitle}>{dayString}</Text>
            <EventsContainer>
                {eventSections}
            </EventsContainer>
        </DayContainer>
    )
}

const ScheduledEvent = (props: {
    event: Scheduled
}) => {
    const { isMobile } = useContext(SiteContext);
    const startText = getTime(props.event.start);
    const endText = getTime(props.event.end);
    const timeText = isMobile ? startText : startText + ' to ' + endText;

    return (
        <ScheduledEventContainer>
            <Text size={isMobile ? theme.fontS : theme.fontMS} style={styles.scheduledTime}>{timeText + ':  '}</Text>
            <Text size={isMobile ? theme.fontS : theme.fontMS} style={styles.scheduledTitle}>{props.event.title}</Text>
        </ScheduledEventContainer>
    )
}

const SectionContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    flex: 1;
    margin-bottom: ${props => props.margin}px;
    padding: 0px 20px;
`;

const DayContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 5px;
    margin-bottom: 25px;
    align-self: flex-start;
`;

const EventsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const ScheduledEventContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    margin-bottom: 5px;
`;

export default RushSchedule;