import React, { useEffect, useContext, useRef } from 'react';
import { HeaderThemes } from 'styles/theme';
import styled from 'styled-components'
import Header from 'components/Header';
import { Text } from 'components/common';
import House from '../data/images/content/32herefordold.jpg';
import { Content } from 'data/types';
import theme from 'styles/theme';
import contentFetcher from 'data/datafetchers/contentFetcher';
import { useImage } from 'helpers/hooks';
import { Helmet } from 'react-helmet-async'
import SiteContext from 'helpers/SiteContext';
import { useContainerDimensions } from '../helpers/hooks'

const MATTERPORT_ASPECT = 0.562719812

const styles = {
    title: {
        fontWeight: theme.fontMedium,
        marginTop: 15,
        marginBottom: -30,
        textAlign: 'left',
        color: theme.dark,
    },
    subtitle: {
        textAlign: 'left',
        fontWeight: theme.fontRegular,
        color: theme.dark,
        marginTop: 30,
        paddingBottom: 5,
    },
    text: {
        fontWeight: theme.fontLight,
    },
    matterport: {
        alignSelf: 'center',
        paddingBottom: 40,
        paddingTop: 20
    }
}

const About = () => {
    const { isMobile, setSite } = useContext(SiteContext);

    useEffect(() => {
        setSite(HeaderThemes.DARK, true);
    }, [setSite])

    const topContent = contentFetcher("aboutTop")
    const middleTopContent = contentFetcher("aboutMiddleTop")
    const middleBottomContent = contentFetcher("aboutMiddleBottom")
    const bottomContent = contentFetcher("aboutBottom")

    return (
        <>
            <Helmet>
                <title>About</title>
                <meta name="description" content="The history and traditions of Chi Phi" />
            </Helmet>
            <Header 
                title="about"
                image={House}
                imagePos='top center'
                cover={true}
            />
            <ContentContainer>   
                <TourSection/>
                <Text style={styles.title} size={theme.fontXL}>History</Text>
                <ContentSection content={topContent} isMobile={isMobile} float={'right'}/>
                <ContentSection content={middleTopContent} isMobile={isMobile} float={'right'}/>
                <ContentSection content={middleBottomContent} isMobile={isMobile} float={'left'}/>
                <ContentSection content={bottomContent} isMobile={isMobile} float={'right'}/>
            </ContentContainer>
        </>
    )
}

const TourSection = () => {
    const sectionRef = useRef();
    const { width } = useContainerDimensions(sectionRef);
    const matterportWidth = width;
    const matterportHeight = matterportWidth*MATTERPORT_ASPECT;

    return (
        <SectionContainer ref={sectionRef}>
            <Text size={theme.fontXL} style={{...styles.subtitle, ...{fontWeight: theme.fontMedium,}}}>Our House</Text> 
            <Text size={theme.fontS} style={styles.text}>Explore our historic house using our Matterport 3D virtual tour. 32 Hereford St. was built in the 1880’s by renowned architects McKim, Mead, and White as the home of Congressman John F. Andrew, son of Massachusetts Governor John A. Andrew. The house remained in the Andrew family until 1950, when it was acquired by the Beta Foundation. Ever since, 32 Hereford St. has served as the residence of members of the Chi Phi Fraternity Beta Chapter at MIT. As you’ll see, the tour highlights historical facts about the house, including information about the Andrew’s family, the architects, and the significance of particular rooms. We hope this tour provides a glimpse into the outstanding house that generations of Chi Phi’s have been lucky enough to call home.</Text>
            <iframe 
				title='House Tour'
                width={matterportWidth}
                height={matterportHeight}
                style={styles.matterport}
                src='https://my.matterport.com/show/?m=CHCWzsFv61D' 
                frameBorder='0' 
                allowFullScreen 
                allow='xr-spatial-tracking'
            />
        </SectionContainer>
    )
}

const ContentSection = (props: {
    content: Content
    isMobile: boolean
    float: 'left' | 'right'
}) => {
    const image = useImage('data/images/content/'+props.content.image+'.png')
    return (
        <SectionContainer>
            {props.content.title ? 
                <Text size={theme.fontML} style={styles.subtitle}>{props.content.title}</Text> 
            : null}
            {image && props.isMobile ? 
                <>
                    <Text size={theme.fontS} style={styles.text}>{props.content.text}</Text>
                    <MobileImage src={image}/> 
                </>: image ?
                <FloatedContent text={props.content.text} image={image} float={props.float}/> :
                <Text size={theme.fontS} style={styles.text}>{props.content.text}</Text>
            }
        </SectionContainer>
    )
}

const MobileImage = styled.img`
    width: 70vw;
    padding: 15px 0px
`;

const FloatedContent = (props: {
    text: string
    image: string
    float: 'left' | 'right'
}) => {
    return (
        <div>
            <div style={{
                float: props.float,
                height: 325, 
                paddingRight: props.float === 'left' ? 20 : 0,
                paddingLeft: props.float === 'right' ? 20 : 0,
                marginBottom: 20
            }}>
                <img src={props.image} alt='' height={325}/>
            </div>
            <Text size={theme.fontS} style={styles.text}>{props.text}</Text>
        </div>
    )
}

const ContentContainer = styled.div`
    margin-top: 45vh;
    margin-bottom: 60px;
    display: flex;
    padding: 0px 180px;
    @media (max-width: 1260px) {
        padding: 0px 90px;
    }
    @media (max-width: 760px) {
        padding: 0px 60px;
    }
    flex-direction: column;
    letter-spacing: 0.5px;
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    white-space:pre-wrap;
`;

export default About