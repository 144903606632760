import { Brother } from "../types";
import Brothers from '../raw/brothers.json';

const brotherFetcher = (id: string): Brother => {
    const brotherObj = Brothers[id];
    const brother = {
        id: id,
        name: brotherObj.name,
        course: brotherObj.major,
        hometown: brotherObj.hometown,
        interests: brotherObj.interests,
        positions: brotherObj.officer,
        image: 'data/images/brothers/' + id + '.jpg'
    }

    return brother;
}

export default brotherFetcher;