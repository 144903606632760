import React from 'react';
import { HeaderThemes } from "styles/theme";

const context: {
    isMobile: boolean;
    showBars: boolean;
    headerColor: HeaderThemes;
    setSite: (header: HeaderThemes, bars: boolean) => void;
} = {
    isMobile: false,
    showBars: true,
    headerColor: HeaderThemes.LIGHT,
    setSite: () => undefined,
}

const SiteContext = React.createContext(context);

export default SiteContext;