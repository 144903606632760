import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import React, { useContext } from 'react';
import SiteContext from 'helpers/SiteContext';
import styled from 'styled-components'
import { MAPS_KEY } from 'data/environment';

const Map = () => {
    const { isMobile } = useContext(SiteContext);

    const location = {
        lat: 42.3501005,
        lng: -71.0863404,
    }

    const mapOptions = {
        mapId: "1713dec854bc83cb",
        streetViewControl: false,
        restriction: {
            latLngBounds: {
                north: location.lat+1,
                south: location.lat-1,
                east: location.lng+1,
                west: location.lng-1,
            }
        },
        mapTypeControl: false,
        fullscreenControl: false,
    }

    const mapContainerStyle = {
        borderRadius: 10,
        height: '100%',
        width: '100%',
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: MAPS_KEY,
    })


    return (
        <Container isMobile={isMobile}>
            {isLoaded ?
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={location}
                zoom={14}
                options={mapOptions}
            >   
                <Marker
                    position={location}
                />
            </GoogleMap>
            : null }
        </Container>
    )
}

const Container = styled.div`
    border-radius: 10px;
    align-items: center;
    width: ${props => props.isMobile ? '100%' : '50%'};
    height: ${props => props.isMobile ? '50vh' : 'auto'};
    margin-top: ${props => props.isMobile ? '40px' : '0px'};
`;

export default Map