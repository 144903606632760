import React from 'react';
import styled from 'styled-components'
import Whiteout from 'components/party/Whiteout';
import House from '../data/images/content/32hereford.jpg';
import { Text } from 'components/common';
import { Link, useHistory } from 'react-router-dom'
import { Content, ContentLink } from 'data/types';
import theme, { HeaderThemes } from 'styles/theme';
import contentFetcher from 'data/datafetchers/contentFetcher';
import { useImage } from 'helpers/hooks';
import { useContext } from 'react';
import SiteContext from 'helpers/SiteContext';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async'
import { PARTY_DATE, RUSH_START } from 'data/environment';

const styles = {
    title: {
        alignContent: 'center',
        textDecoration: 'none',
        color: theme.light,
        fontWeight: theme.fontRegular,
        paddingTop: '35vh',
        paddingBottom: 30,
        textAlign: 'center',
    },
    sectionTitle: {
        alignContent: 'center',
        textDecoration: 'none',
        color: theme.light,
        fontWeight: theme.fontRegular,
        textTransform: 'uppercase',
        height: '40vh',
        lineHeight: '40vh',
        textAlign: 'center',
    },
    lightButtonText: {
        paddingTop: 12,
        paddingBottom: 8,
        borderRadius: 25,
        textAlign: 'center',
        margin: 'auto',
        cursor: 'pointer',
        fontWeight: theme.fontRegular,
        minWidth: 300,
    },
    darkButtonText: {
        paddingTop: 12,
        paddingBottom: 8,
        borderRadius: 25,
        borderColor: theme.dark,
        textAlign: 'center',
        margin: 'auto',
        cursor: 'pointer',
        fontWeight: theme.fontRegular,
        minWidth: 300,
    },
    subtitle: {
        fontWeight: theme.fontMedium,
        textAlign: 'center',
        color: theme.dark,
        paddingBottom: 10,
    },
    linkContainer: {
        paddingTop: 5,
        paddingBottom: 5,
    },
    text: {
        textAlign: 'center',
        fontWeight: theme.fontLight,
        paddingBottom: 30
    },
}

const Home = () => {
    const { isMobile, setSite } = useContext(SiteContext);
    const history = useHistory();
    const showParty = false;
    // const showParty = history.action === 'POP';
    // const showParty = history.action === 'POP' && RUSH_START < Date.now() && Date.now() < PARTY_DATE;

    useEffect(() => {
        setSite(HeaderThemes.LIGHT, !showParty);
    }, [setSite, showParty])

    const intro = contentFetcher('homeIntro');
    const social = contentFetcher('homeSocial');
    const academics = contentFetcher('homeAcademics');
    const alumni = contentFetcher('homeAlumni');

    return (
        <>
            <Helmet>
                <title>Chi Phi Beta Chapter</title>
                <meta name="description" content="The Beta Chapter of the Chi Phi fraternity at MIT" />
            </Helmet>
            {showParty ? 
                <Whiteout/> :
                <>
            <HomeHeader 
                title="&Chi;&Phi; x MIT"
                image={House}
                imagePos='no-repeat top center'
                cover={true}
            />
            <ContentContainer>
                <Text size={isMobile ? theme.fontML : theme.fontL} style={styles.subtitle}>{intro.title}</Text>
                <Text size={theme.fontS} style={styles.text}>{intro.text}</Text>

                <Link className='noDecor' to={'/about'}>
                    <Text className='select' style={{...styles.darkButtonText, color: theme.dark, ...{width: isMobile ? '80vw' : '30vw'}}} size={theme.fontMS}>
                        Tour the House
                    </Text>
                </Link>
            </ContentContainer>

            <DetailSection content={social} isMobile={isMobile}/>
            <DetailSection content={academics} isMobile={isMobile}/>
            <DetailSection content={alumni} isMobile={isMobile}/>
            <Link to={{ pathname: "https://accessibility.mit.edu" }} target="_blank"> 
                <Text style={{...styles.darkButtonText, color: theme.dark, ...{width: isMobile ? '80vw' : '30vw'}}} size={theme.fontXS}>
                    accessibility.mit.edu
                </Text>
            </Link>
                </>
            }
        </>
    )
}

const HomeHeader = (props: {
    title: string
    image: string
    imagePos: string
    cover: boolean
}) => {
    const { isMobile } = useContext(SiteContext)

    return (
        <Container background={props.image} position={props.imagePos} cover={props.cover}>
            <Text style={styles.title} size={isMobile ? theme.fontML : theme.fontXL}>{props.title}</Text>
            <Link className='noDecor' to={'/brothers'}>
                <Text className='select' style={{...styles.lightButtonText, ...{width: isMobile ? '90vw' : '30vw'}}} size={theme.fontMS}>
                    Meet the Brothers
                </Text>
            </Link>
        </Container>
    )
}

const LinkedText = (props: {
    text: string
    links: ContentLink[]
}) => {
    if (!props.links) return <>{props.text}</>

    const linkPhrases = props.links.map(l => l.text);
    const regex = new RegExp('(' + linkPhrases.reduce((reg, str) => reg + '|' + str) + ')');
    const splitText = props.text.split(regex);

    const linkComponents = props.links.map(l => {
        return {
            text: l.text,
            component: <a key={l.text} href={l.link} target="_blank" rel='noreferrer' style={{color: theme.linkBlue}}>{l.text}</a>
        }
    })

    const allComponents = splitText.map(s => {
        if (linkPhrases.includes(s)) {
            return (linkComponents.find(lc => lc.text === s)?.component)
        } else {
            return s
        }
    })

    return <>{allComponents}</>
}

const DetailSection = (props: {
    content: Content
    isMobile: boolean
}) => {
    const image = useImage('data/images/content/'+props.content.image+'.jpg');
    return (
        <DetailsContainer>
            <SectionImage background={image} isMobile={props.isMobile}>
                <Text style={styles.sectionTitle} size={props.isMobile ? theme.fontML : theme.fontXL}>{props.content.title}</Text>
            </SectionImage>
            <TextContainer>
                <LinkedText text={props.content.text} links={props.content.links}/>
            </TextContainer>
        </DetailsContainer>
    )
}

const Container = styled.div`
    width: 100vw;
    height: 90vh;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    background: url(${props => props.background}) ${props => props.position};
    background-size: ${props => props.cover ? 'cover' : 'auto'};
    z-index: 1;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 30px 0px;
    white-space: pre-wrap;
`;

const TextContainer = styled.div`
    text-align: center;
    letter-spacing: 0.5px;
    font-size: ${theme.fontS};
    font-weight: ${theme.fontLight};
    padding: 70px 150px 40px 150px;
    @media (max-width: 1260px) {
        padding: 25px 80px;
    }
    @media (max-width: 760px) {
        padding: 25px 30px;
    }
`;

const SectionImage = styled.div`
    width: 100vw;
    height: 40vh;
    text-align: center;
    background:  linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${props => props.background}) ${props => props.isMobile ? '' : 'no-repeat'} top center;
    background-attachment: ${props => props.isMobile ? 'auto' : 'fixed'};
    background-size: cover;
    z-index: 1;
`;

const ContentContainer = styled.div`
    margin-top: calc(100vh - 72px);
    margin-bottom: 60px;
    display: flex;
    padding: 0px 150px;
    @media (max-width: 1260px) {
        padding: 0px 80px;
    }
    @media (max-width: 760px) {
        padding: 0px 30px;
    }
    flex-direction: column;
    letter-spacing: 0.5px;
    white-space: pre-wrap;
`;

export default Home