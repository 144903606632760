const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTH = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'November', 'December'];

export const getTimeTillString = (timeTill: number): string => {
    const days = Math.floor(timeTill/DAY);
    const daysString = days.toString();
    timeTill -= days*DAY;
    const hours = Math.floor(timeTill/HOUR);
    const hoursString = (hours < 10 ? '0' : '') + hours.toString()
    timeTill -= hours*HOUR;
    const mins = Math.floor(timeTill/MINUTE);
    const minsString = (mins < 10 ? '0' : '') + mins.toString()
    timeTill -= mins*MINUTE;
    const seconds = Math.floor(timeTill/SECOND);
    const secondsString = (seconds < 10 ? '0' : '') + seconds.toString()

    return (days > 0 ? daysString + ':' : '') + (hours > 0 ? hoursString + ':' : '') + (mins > 0 ? minsString + ':' : '') + secondsString;
}

export const getTime = (time: string) => {
    var string24 = time || '0000';
    while (string24.length < 4) string24='0'+string24;

    const hours = parseInt(string24.substring(0, 2)) || 12;
    const tod = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours > 12 ? (hours-12).toString() : hours.toString();
    const mins = string24.substring(2, 4);
    return hours12 + ':' + mins + tod;
}

export const stringDay = (day: string, full?: boolean): string => {
    const date = new Date(day);
    date.setDate(date.getDate() + 1);

    const ofWeek = WEEK[date.getDay()] + (full ? ',' : '');
    const month = MONTH[date.getMonth()];
    const dayNum = ordinalSuffix(date.getDate());

    return full ? [ofWeek, month, dayNum].join(' ') : ofWeek;
}

const ordinalSuffix = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}