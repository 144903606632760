import { Employer } from "../types"
import employers from '../raw/employers.json'

const employersFetcher = (): Employer[] => {
    const employersList = employers["employers"].map(e => {
        if (["odds", "nightowl", "vault", "blackstone", "triplecrowntrading"].includes(e)) {
            return {name: e,
                    image: 'data/images/employers/' + e + '.png'}
        } else {
            return {name: e,
                    image: 'data/images/employers/' + e + '.jpg'}
        }
    });
    console.log(employersList)
    return employersList
}

export default employersFetcher;