
    
import React, { useEffect, useContext } from 'react';
import { HeaderThemes } from 'styles/theme';
import _ from 'lodash';
import styled from 'styled-components'
import Header from 'components/Header';
import BrothersImage from '../data/images/content/2023-retreat.jpg';
import classes from '../data/raw/classes.json'
import { useWindowSize } from '../helpers/hooks';
import classFetcher from 'data/datafetchers/classFetcher';
import BrotherTile from 'components/BrotherTile';
import { Text } from 'components/common';
import { Helmet } from 'react-helmet-async'
import theme from 'styles/theme';
import SiteContext from 'helpers/SiteContext';


const styles = {
    year: {
        color: theme.dark,
        fontWeight: theme.fontBold,
        alignItems: 'center',
        paddingTop: 40,
    }
}

const Brothers = () => {
    const { setSite } = useContext(SiteContext)

    useEffect(() => {
        setSite(HeaderThemes.LIGHT, true);
    }, [setSite])

    const classSections = Object.keys(classes).map(c => {
        return (
            <ClassSection key={c} classYear={c}/>
        )
    })
    
    return (
        <>
            <Helmet>
                <title>Brothers</title>
                <meta name="description" content="Meet the brothers of Chi Phi" />
            </Helmet>
            <Header 
                title="brothers"
                image={BrothersImage}
                imagePos='top center'
                cover={true}
            />
            <ContentContainer>
                {classSections}
            </ContentContainer>
        </>
    )
}

const ClassSection = (props: {
    classYear: string
}) => {
    const classBrothers = classFetcher(props.classYear)
    const windowSize = useWindowSize();
    const numCols = Math.floor(windowSize.width/300)
    const isMobile = numCols === 1;

    const rows = _.chunk(classBrothers.brothers, numCols);
    const rowSections = rows.map(row => {
        const tiles = row.map(bro => {
            return (
                <BrotherTile key={bro.id} brother={bro} isMobile={isMobile}/>
            )
        })
        const key = row.map(r => r.id).reduce((i, j) => i + j);
        return (
            <RowContainer key={key}>
                {tiles}
            </RowContainer>
        )
    })

    return (
        <ClassContainer>
            <Text style={styles.year} size={theme.fontXL}>{props.classYear}</Text>
            {rowSections}
        </ClassContainer>
    )
}

const ClassContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

const RowContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const ContentContainer = styled.div`
    margin-top: 45vh;
    display: flex;
    padding: 0px 90px;
    @media (max-width: 1150px) {
        padding: 0px 60px;
    }
    @media (max-width: 760px) {
        padding: 0px 30px;
    }
    flex-direction: column;
`;

export default Brothers
