import React, { useEffect, useContext } from 'react';
import { HeaderThemes } from 'styles/theme';
import styled from 'styled-components';
import RushPic from 'data/images/content/nayparty.jpg'
import Header from 'components/Header';
import theme from 'styles/theme';
import PastEvents from 'components/rush/PastEvents';
import RushSchedule from 'components/rush/Schedule';
import { RUSH_START, RUSH_TEXTS } from 'data/environment';
import SiteContext from 'helpers/SiteContext';
import RushCountdown from 'components/rush/Countdown';
import Directions from 'components/rush/Directions';
import TextAlerts from 'components/rush/TextAlerts';
import { Helmet } from 'react-helmet-async'

const Rush = () => {
    const { isMobile, setSite } = useContext(SiteContext);

    useEffect(() => {
        setSite(HeaderThemes.LIGHT, true);
    }, [setSite])

    const hasBegun = RUSH_START < Date.now();
    return (        
        <>
            <Helmet>
                <title>Rush</title>
                <meta name="description" content="Rush with Chi Phi starting September 3rd" />
            </Helmet>
            <Header 
                title={(hasBegun ? 'welcome' : 'countdown') + (isMobile ? '\n' : '') +  ' to CPW 2023'}
                image={RushPic}
                imagePos='top center'
                cover={false}
            />
            <ContentContainer>
                {hasBegun ? 
                <>
                    <RushSchedule/> 
                    {RUSH_TEXTS ? 
                        <>
                            <Divider/>
                            <TextAlerts/>
                        </>
                    : null}
                </>:
                <>
                    <RushCountdown/>
                    <Divider/>
                    <PastEvents/>
                </>}
                <Divider/>
                <Directions/>
            </ContentContainer>
        </>
    )
};

const ContentContainer = styled.div`
    margin-top: calc(50vh - 72px);
    margin-bottom: 60px;
    display: flex;
    padding: 0px 150px;
    @media (max-width: 1260px) {
        padding: 0px 80px;
    }
    @media (max-width: 760px) {
        padding: 0px 30px;
    }
    flex-direction: column;
    letter-spacing: 0.5px;
    white-space: pre-wrap;
`;

const Divider = styled.div`
    border-bottom: 2px solid ${theme.lightGrey};
    height: 2px;
    padding: 10px 20px;
    margin-bottom: 20px;
`;

export default Rush;