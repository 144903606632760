import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import theme from 'styles/theme';
import axios from 'axios';
import SiteContext from 'helpers/SiteContext';
import { Text, Button } from 'components/common';

const styles = {
    titleText: {
        fontWeight: theme.fontRegular,
        color: theme.dark,
        marginTop: 40, 
    },
    subtitle: {
        fontWeight: theme.fontRegular,
        color: theme.dark,
        marginTop: 10, 
    },
    formItem: {
        background: 'transparent',
        border: 'none',
        height: 50,
        color: theme.dark,
        fontSize: theme.fontMS,
        fontWeight: theme.fontRegular,
    },
    buttonStyle: {
        fontWeight: theme.fontBold,
        fontSize: theme.fontMS,
        cursor: 'pointer',
        height: 50,
        borderRadius: 5,
        marginTop: 8,
    },
}

const TextAlerts = () => {
    const { isMobile } = useContext(SiteContext);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');

    const subtitle = error ? "An error occured while signing you up." : submitted ? "You're all signed up!" : "Sign-up to receive text reminders before Chi Phi events";

    const handleSubmit = async () => {
        if (name !== '' && number !== '') {
            const formData = {
                name: name,
                number: number,
            }

            try {
                const res = await axios.post('/api/rushtexts/add', formData);
                if (res.data) {
                    setSubmitted(true);
                } else {
                    setError(true);
                }
            } catch (e) {
                setError(true);
            }
        }
    }

    return (
        <SectionContainer margin={40}>
            <Text size={isMobile ? theme.fontL : theme.fontXXL} style={styles.titleText}>Get Text Reminders</Text>
            <Text size={isMobile ? theme.fontMS : theme.fontML} style={styles.subtitle}>{subtitle}</Text>
            {submitted ? null :
                <FormContainer>
                    <FormContainerInner>
                        <ItemContainer>
                            <input 
                                style={styles.formItem}
                                onChange={e => setName(e.target.value)}
                                type="text"
                                placeholder="Name"
                            />
                        </ItemContainer>
                        <ItemContainer>
                            <input 
                                style={styles.formItem}
                                onChange={e => setNumber(e.target.value)}
                                type="tel"
                                placeholder="Phone Number"
                            />
                        </ItemContainer>
                        <Button 
                            title='Get Texts'
                            className='selectDark'
                            styles={styles.buttonStyle}
                            onPress={() => {
                                handleSubmit()
                            }}
                        />
                    </FormContainerInner>
                </FormContainer>
            }
        </SectionContainer>
    )
};

const SectionContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    flex: 1;
    margin-bottom: ${props => props.margin}px;
    padding: 0px 20px;
`;

const FormContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 20px 0px;
    align-self: stretch;
`;

const FormContainerInner = styled.div`
    display: flex;
    flex: 1;
    max-width: 450px;
    flex-direction: column;
    margin: auto;
`;

const ItemContainer = styled.div`
    background: ${theme.lightBackground};
    padding: 0px 20px;
    margin: 8px 0px;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 5px;
`;

export default TextAlerts;