import React, { useState, useContext, useEffect } from "react"
import styled from 'styled-components'
import theme from "styles/theme";
import { Text } from "components/common";
import { Link } from 'react-router-dom';
import SiteContext from "helpers/SiteContext";
import { HeaderThemes } from "styles/theme";
import { Helmet } from 'react-helmet-async'

const styles = {
    visit: {
        fontWeight: theme.fontRegular,
        color: theme.white,
    },
    link: {
        textDecoration: "none",
    },
    text: {
        fontWeight: theme.fontRegular,
        color: theme.black,
        textAlign: 'center',
        marginTop: 10,
    },
    altText: {
        fontWeight: theme.fontRegular,
        color: theme.white,
        background: theme.black,
        paddingLeft: 2,
        paddingRight: 2,
        marginLeft: 5,
        marginTop: 10,
    }
}

const Whiteout = () => {
    const { isMobile, setSite } = useContext(SiteContext);
    const textSize = isMobile ? theme.fontML : theme.fontL;

    useEffect(() => {
        setSite(HeaderThemes.LIGHT, false);
    }, [setSite])

    const [lines, setLines] = useState(['', '', '', '', '']);
    const [typeRate, setTypeRate] = useState(100);

    useEffect(() => {
        const completeLines = ['Whiteout', 'presented by ', 'Chi Phi', '32 hereford st', '4/13/24 10pm'];
        let interval = setInterval(() => {
            let currentLines = [...lines];
            let index = 0;
            let set = false;
            while (!set && currentLines !== completeLines) {
                let completeLine = completeLines[index];
                let currentLine = currentLines[index];
                if (currentLine === completeLine) {
                    if (index === completeLines.length) {
                        set = true;
                        setTypeRate(86400000);
                    } else {
                        index++
                    }
                } else {
                    currentLines[index] = completeLine.slice(0, currentLine.length+1)
                    set = true
                    setLines(currentLines);
                }
            }
        }, typeRate)
        return ()=> {
            clearInterval(interval);
        };
    }, [lines, typeRate]);

    return (
        <>
            <Helmet>
                <title>32 Hereford | 10PM 9/3/22</title>
                <meta name="description" content="Join us at 32 Hereford St 10pm on September 3rd" />
            </Helmet>
            <Container className='times'>
                <Link to="/" style={styles.link}>
                    <VisitChiPhi isMobile={isMobile}>
                        <Text size={isMobile ? theme.fontM : theme.fontL} style={styles.visit}>Visit Main Site</Text>
                    </VisitChiPhi>
                </Link>
                <TextSection isMobile={isMobile}>
                    <Text size={isMobile ? theme.fontXXL : theme.fontHuge} style={styles.text}>{lines[0]}</Text>
                    <RowText>
                        <Text size={textSize} style={styles.text}>{lines[1]}</Text>
                        {lines[2] ? 
                            <Text size={textSize} style={styles.altText}>{lines[2]}</Text>
                        : null}
                    </RowText>
                    <a href='https://www.google.com/maps/place/32+Hereford+St,+Boston,+MA+02115/@42.3501574,-71.0884104,622m' 
                        target='_blank'
                        className='hoverLink'
                        rel='noreferrer'
                    >
                        <Text size={textSize} style={styles.text}>{lines[3]}</Text>
                    </a>
                    <Text size={textSize} style={styles.text}>{lines[4]}</Text>
                </TextSection>
            </Container>
        </>
    )
}

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${theme.white};
`;

const VisitChiPhi = styled.div`
    margin-top: ${props => props.isMobile ? '20px' : '30px'};
    margin-left: ${props => props.isMobile ? '20px' : '30px'};
    margin-right: auto;
    padding: 2px 4px;
    display: inline-block;
    background: ${theme.black};
`;

const TextSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: ${props => props.isMobile ? '80%' : '60%'};
    justify-content: center;
`;

const RowText = styled.div`
    display: flex;
    flex: 0;
    justify-content: center;
`;

export default Whiteout;