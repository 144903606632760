import { Text } from 'components/common'
import React from 'react'
import styled from 'styled-components'
import theme from 'styles/theme'
import { Helmet } from 'react-helmet-async'

const NotFound = () => (
    <>
        <Helmet>
            <title>404</title>
            <meta name="description" content="Page not found" />
        </Helmet>
        <Container>
            <Text size={theme.fontL} style={{paddingTop: 25, paddingLeft: 25}}>Page Not Found</Text>
        </Container>
    </>
)

const Container = styled.div`
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${theme.white};
`;

export default NotFound