import { Brother } from 'data/types';
import { useImage } from 'helpers/hooks';
import React from 'react';
import styled from 'styled-components'
import theme from 'styles/theme';
import { Text } from './common';

const styles = {
    name: {
        color: theme.dark,
        fontWeight: theme.fontBold,
        textAlign: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    },
    itemContent: {
        color: theme.dark,
        fontWeight: theme.fontRegular,
        fontSize: theme.fontS,
        paddingTop: 4,
    }
}

const BrotherTile = (props: {
    brother: Brother
    isMobile: boolean
}) => {
    const image = useImage(props.brother.image);
    return (
        <TileContainer isMobile={props.isMobile}>
            <Composite src={image}/>
            <InfoContainer>
                <InnerInfoContainer isMobile={props.isMobile}>
                    <Text style={styles.name} size={theme.fontMS}>{props.brother.name}</Text>
                    <InfoItemSection title="Course: " content={props.brother.course}/>
                    <InfoItemSection title="Hometown: " content={props.brother.hometown}/>
                    <InfoItemSection title="Interests: " content={props.brother.interests}/>
                    {props.brother.positions ? 
                        <InfoItemSection title="Officer Positions: " content={props.brother.positions}/> 
                    : null}
                </InnerInfoContainer>
            </InfoContainer>
        </TileContainer>
    )
}

const InfoItemSection = (props: {
    title: string
    content: string
}) => {
    return (
        <div style={styles.itemContent}>
            <strong style={{fontWeight: theme.fontBold}}>
                {props.title}
            </strong>
            {props.content}
        </div>
    )
}

const TileContainer = styled.div`
    width: ${props => props.isMobile ? '100%' : '260px'};
    padding: 0px;
    margin: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    letter-spacing: 0.5px;
    align-items: stretch;
`;

const InfoContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${theme.lightBackground};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

const InnerInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
`;

const Composite = styled.img`
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center top;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;

export default BrotherTile