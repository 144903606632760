import React, { useEffect, useContext } from 'react';
import { HeaderThemes } from 'styles/theme';
import Header from 'components/Header';
import styled from 'styled-components'
import { useImage, useWindowSize } from '../helpers/hooks';
import employersFetcher from 'data/datafetchers/employersFetcher';
import BrothersImage from '../data/images/content/2025-ring-delivery.jpg';
import theme from 'styles/theme';
import SiteContext from 'helpers/SiteContext';
import { Text } from 'components/common';
import { Employer } from 'data/types';
import { Helmet } from 'react-helmet-async'

const MAX_ASPECT_RATIO = 4;
const LOGO_HEIGHT = 120;

const styles = {
    title: {
        fontWeight: theme.fontBold,
        color: theme.dark,
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 40,
        paddingBottom: 20,
        letterSpacing: 0.5,
    },
}

const WhereWeWork = () => {
    const windowSize = useWindowSize()
    const singleImage = windowSize.width < 60 + MAX_ASPECT_RATIO*LOGO_HEIGHT;
    const { isMobile, setSite } = useContext(SiteContext)

    useEffect(() => {
        setSite(HeaderThemes.LIGHT, true);
    }, [setSite])

    const employers = employersFetcher();
    const employerIcons = employers.map(e => {
        return (
            <EmployerTile key={e.name} employer={e} singleImage={singleImage}/>
        )
    })
    
    return (
        <>
            <Helmet>
                <title>Where We Work</title>
                <meta name="description" content="Explore where past brothers have gone on to work" />
            </Helmet>
            <Header 
                title="where we work"
                image={BrothersImage}
                imagePos='center 70%'
                cover={true}
            />
            <ContentContainer>
                <Text style={styles.title} size={isMobile ? theme.fontMS : theme.fontL}>Over the past 10 years, Brothers have gone on to 
                work in these prestigious institutions. This collection is not exhaustive.</Text>
                <EmployersRow singleImage={singleImage}>
                    {employerIcons}
                </EmployersRow>
            </ContentContainer>
        </>
    )
}

const EmployerTile = (props: {
    employer: Employer
    singleImage: boolean
}) => {
    const image = useImage(props.employer.image);
    return <EmployerLogo src={image} singleImage={props.singleImage}/>
}


const EmployerLogo = styled.img`
    height: ${props => props.singleImage ? 'auto' : LOGO_HEIGHT+'px'};
    width: ${props => props.singleImage ? '100%' : 'auto'};
    padding: ${props => props.singleImage ? '30px 0px' : '30px 20px'};
`;

const EmployersRow = styled.div`
    display: flex;
    flex-direction: ${props => props.singleImage ? 'column' : 'row'};
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

const ContentContainer = styled.div`
    margin-top: 45vh;
    margin-bottom: 60px;
    display: flex;
    padding: 0px 60px;
    @media (max-width: 760px) {
        padding: 0px 30px;
    }
    align-items: center;
    flex-direction: column;
`;

export default WhereWeWork;