import React, { useState } from 'react';
import styled from 'styled-components'
import Home from './pages/Home';
import About from 'pages/About';
import Brothers from 'pages/Brothers';
import WhereWeWork from 'pages/WhereWeWork';
import Contact from 'pages/Contact';
import Rush from 'pages/Rush';
import Party from 'pages/Party';
import NotFound from 'pages/404';
import Footer from 'components/Footer';
import NavBar from 'components/NavBar';
import SiteContext from 'helpers/SiteContext';
import { PARTY_PAGE, RUSH_PAGE, RUSH_START } from 'data/environment';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { HeaderThemes } from 'styles/theme';
import { useWindowSize } from 'helpers/hooks';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  const [headerColor, setHeaderColor] = useState(HeaderThemes.LIGHT)
  const [showBars, setShowBars] = useState(true);
  const { width } = useWindowSize();
  const isMobile = width < 580;

  return (
    <HelmetProvider>
      <SiteContext.Provider
        value={{
          isMobile: isMobile,
          showBars: showBars,
          headerColor: headerColor,
          setSite: (header: HeaderThemes, bars: boolean) => {
            setHeaderColor(header);
            setShowBars(bars);
          }
        }}
      >
      <BrowserRouter>
        <FooterBox>
          <Container>
            {showBars ? 
              <NavBar/>
            : null}
            <Switch>
              <Route 
                exact 
                path='/'
                component={Home}
              />
              <Route 
                exact 
                path='/about'
                component={About}
              />
              <Route 
                exact 
                path='/brothers'
                component={Brothers}
              />
              <Route 
                exact 
                path='/wherewework'
                component={WhereWeWork}
              />
              <Route 
                exact 
                path='/contact'
                component={Contact}
              />
              {RUSH_PAGE ? 
                <Route 
                  exact 
                  path='/rush'
                  component={Rush}
                />
              : null}
              {PARTY_PAGE && RUSH_START < Date.now() ? 
                <Route 
                  exact 
                  path='/party'
                  component={Party}
                />
              : null}
              <Route 
                exact
                path='/private'
                render={() => { 
                  window.location.href = 'https://chiphi.scripts.mit.edu:444/cp-private-website/';
                  return null;
                }}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </Container>
          {showBars ? 
            <>
              <Dummy/>
              <Footer/>
            </>
          : null}
        </FooterBox>
      </BrowserRouter>
      </SiteContext.Provider>
    </HelmetProvider>
  );
}

const Container = styled.div`
  padding: 0rem 0rem 0rem 0rem;
  width: 100%;
  overflow: hidden;
`;

const FooterBox = styled.div`
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Dummy = styled.div`
  display: flex;
  flex: 1;
`;

export default App;
