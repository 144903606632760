import React from 'react';
import { Officer } from 'data/types';
import { useImage } from 'helpers/hooks';
import styled from 'styled-components'
import theme from 'styles/theme';
import { Text, Button } from './common';

const styles = {
    title: {
        color: theme.dark,
        fontWeight: theme.fontBold,
        textAlign: 'center',
        paddingTop: 15,
    },
    name: {
        color: theme.dark,
        fontWeight: theme.fontMedium,
        textAlign: 'center',
        paddingTop: 10,
        marginBottom: -5,
    },
    email: {
        textDecoration: 'underline',
        color: theme.linkBlue,
        fontWeight: theme.fontLight,
        textAlign: 'center',
        fontSize: theme.fontMS,
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        outline: 'none',
        margin: 'auto',
    }
}

const OfficerTile = (props: {
    officer: Officer
    singleCol: boolean
}) => {
    const image = useImage(props.officer.image);
    const capitalizedPosition = props.officer.position.split(' ').map(
                                    w => w[0].toUpperCase() + w.substring(1)
                                ).join(' ');

    return (
        <TileContainer>
            <InnerInfoContainer>
                <Text style={styles.title} size={theme.fontXL}>{capitalizedPosition}</Text>
                <Composite singleCol={props.singleCol} img={image}/>
                <Text style={styles.name} size={theme.fontML}>{props.officer.name}</Text>
                <Button 
                    title={props.officer.email}
                    styles={styles.email} 
                    onPress={() => {
                        window.open('mailto:'+props.officer.email, '_blank')
                    }}
                />
            </InnerInfoContainer>
        </TileContainer>
    )
}

const TileContainer = styled.div`
    padding: 0px;
    margin: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    letter-spacing: 0.5px;
`;

const InnerInfoContainer = styled.div`
    max-width: max(90vw, 200px);
    display: flex;
    flex-direction: column;
    margin: auto;
    flex: 1;
`;

const Composite = styled.div`
    height: ${props => props.singleCol ? '70vw' : '40vw'};
    width: ${props => props.singleCol ? '70vw' : '40vw'};
    max-width: 480px;
    max-height: 480px;
    border-radius: 50%;
    background-image: url(${props => props.img});
    background-position: top center;
    background-size: cover;
    margin: 5px 0;
`;

export default OfficerTile