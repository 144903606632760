import theme from "styles/theme"
import React, { useEffect, useContext, useState } from "react"
import styled from 'styled-components'
import SiteContext from "helpers/SiteContext"
import { Text } from "components/common"
import { getTimeTillString } from 'helpers/time';
import { RUSH_START } from "data/environment"

const styles = {
    titleText: {
        fontWeight: theme.fontRegular,
        color: theme.dark,
        marginTop: 30, 
    },
    countdown: {
        fontWeight: theme.fontLight,
        color: theme.dark,
        paddingVertical: 10,
    },
    subTitleText: {
        fontWeight: theme.fontMedium,
        color: theme.dark,
    }
}

const RushCountdown = () => {
    const { isMobile } = useContext(SiteContext)
    const [time, setTime] = useState('');

    useEffect(() =>{
        let interval = setInterval(() => {
            const timeTill = RUSH_START - Date.now();
            const timeString = getTimeTillString(timeTill)
            setTime(timeString);
        }, 1000)
        return ()=> {
            clearInterval(interval);
        };
    }, []);

    return (
        <SectionContainer>
            <Text size={isMobile ? theme.fontML : theme.fontXL} style={styles.titleText}>Rush begins in...</Text>
            <Text size={isMobile ? theme.fontXXL : theme.fontXXXXL} style={styles.countdown}>{time}</Text>
            <Text size={isMobile ? theme.fontS : theme.fontMS} style={styles.subTitleText}>Check back once rush starts for this year's schedule</Text>
        </SectionContainer>
    )
}

const SectionContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    flex: 1;
    margin-bottom: 50px;
    padding: 0px 20px;
`;

export default RushCountdown;