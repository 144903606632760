import SiteContext from 'helpers/SiteContext';
import React from 'react';
import { HeaderThemes } from 'styles/theme';
import { useContext } from 'react';
import styled from 'styled-components'
import theme from 'styles/theme';
import { Text } from './common';

const styles = {
    title: {
        paddingTop: '10vh',
        alignContent: 'center',
        textDecoration: 'none',
        color: theme.light,
        fontWeight: theme.fontRegular,
        textTransform: 'uppercase',
        marginTop: '12vh',
        textAlign: 'center',
    },
}

const Header = (props: {
    title: string
    image: string
    imagePos: string
    cover: boolean
}) => {
    const { isMobile, headerColor } = useContext(SiteContext)
    const textColor = headerColor === HeaderThemes.LIGHT ? theme.light : theme.dark;
    return (
        <Container background={props.image} position={props.imagePos} cover={props.cover} isMobile={isMobile}>
            <Text style={{...styles.title, ...{color: textColor}}} size={isMobile ? theme.fontML : theme.fontXL}>{props.title}</Text>
        </Container>
    )
}

const Container = styled.div`
    width: 100vw;
    height: 50vh;
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    background: url(${props => props.background}) ${props => props.isMobile ? '' : 'no-repeat'} ${props => props.position};
    background-attachment: ${props => props.isMobile ? 'auto' : 'fixed'};
    background-size: ${props => props.cover ? 'cover' : 'auto'};
    z-index: 1;
    white-space: pre-wrap;
`;

export default Header