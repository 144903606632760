import styled from 'styled-components';
import theme from '../styles/theme';

export const Title = styled.div`
    color: ${theme.black};
    font-size: ${theme.fontXXXL};
    font-family: ${theme.fontRegular};
`;

export const PageSubTitle = styled.div`
    color: ${theme.dark};
    font-size: ${theme.fontML};
    font-family: ${theme.fontLight};
`;

export const SubTitle = styled.div`
    color: ${theme.dark};
    font-size: ${theme.fontXL};
    font-family: ${theme.fontRegular};
`;

export const Text = styled.div`
    font-size: ${props => props.size || theme.fontM};
`;

export const Image = styled.img`
    width: ${props => props.width || 30};
    height: ${props => props.height || 30};
    margin: 10px;
`;

export const Button = (props: {
    title: string;
    styles?: any
    className?: string
    disabled?: boolean
    onPress: () => void;
}) => {
    return (
        <button onClick={props.onPress} style={props.styles} className={props.className} disabled={props.disabled || false}>
            {props.title}
        </button>
    )
}