import { Content } from 'data/types';
import content from '../raw/content.json'
import rush from '../raw/rush.json'

const contentFetcher = (id: string, isRush?: boolean): any => {
    if (isRush) { // NOT STRONGLY TYPED, PAGE WILL BREAK IF DEVIATE FROM CURRENT FORMAT
        const rushObj = rush[id]
        if (id === 'past') {
            const events = Object.keys(rushObj).map(e => {
                return {
                    "title": e,
                    "image": rushObj[e]
                }
            })
            return events;
        }
        return rushObj;
    }
    const contentPiece = content[id];
    const linkTitles = contentPiece.links && Object.keys(contentPiece.links)
    const links = linkTitles && linkTitles.map(l => { 
        return {
            "text": l,
            "link": contentPiece.links[l]
        }
    })
    const contentObj: Content = {
        id: id,
        title: contentPiece.title,
        text: contentPiece.text,
        image: contentPiece.image,
        links: links
    }

    return contentObj
}

export default contentFetcher;