import React, { useContext, useState, useRef } from "react";
import styled from 'styled-components'
import contentFetcher from "data/datafetchers/contentFetcher";
import SiteContext from "helpers/SiteContext";
import theme from "styles/theme";
import ArrowRight from 'data/images/rush/arrowRight.svg'
import ArrowRightDisabled from 'data/images/rush/arrowRightDisabled.svg'
import ArrowLeft from 'data/images/rush/arrowLeft.svg'
import ArrowLeftDisabled from 'data/images/rush/arrowLeftDisabled.svg'
import { Text } from "components/common";
import { useContainerDimensions, useImage } from "helpers/hooks";

const PAST_ASPECT = 1.4;

const styles = {
    titleText: {
        fontWeight: theme.fontRegular,
        color: theme.dark,
        marginTop: 40, 
    },
    event: {
        fontWeight: theme.fontMedium,
        color: theme.dark,
        textAlign: 'center',
        marginTop: 10,
        marginBottom: 10, 
    },
    scheduledTitle: {
        fontWeight: theme.fontRegular,
        color: theme.dark,
        textAlign: 'left',
    },
    scheduledTime: {
        fontWeight: theme.fontMedium,
        color: theme.dark,
        textAlign: 'left',
    },
    dayTitle: {
        fontWeight: theme.fontMedium,
        color: theme.dark,
        textAlign: 'left',
    }
}

const PastEvents = () => {
    const { isMobile } = useContext(SiteContext);
    const [index, setIndex] = useState(0);
    const tileRef = useRef();
    const { width } = useContainerDimensions(tileRef);
    const useableWidth = width - 85*2;
    const numTiles = isMobile || useableWidth < 700 ? 1 : 2;
    const tileWidth = numTiles === 1 ? useableWidth : useableWidth/2;

    const events = contentFetcher('past', true);
    const eventTiles = events.map(e => {
        return (
            <PastEventTile
                key={e.title}
                title={e.title}
                image={e.image}
                width={tileWidth}
            />
        )
    })

    const leftDisabled = index === 0;
    const rightDisabled = index === eventTiles.length-numTiles;
    const visibleTiles = isMobile ? eventTiles : eventTiles.slice(index, index+numTiles)

    return (
        <SectionContainer ref={tileRef} margin={50}>
            <Text size={isMobile ? theme.fontXL : theme.fontXXL} style={styles.titleText}>Past Events</Text>
            <PastEventsContainer isMobile={isMobile}>
                {!isMobile ?
                <ArrowButton 
                    onClick={() => {setIndex(index-1)}} 
                    disabled={leftDisabled}
                    right={0}
                    left={10}
                >
                    <ArrowIcon 
                        src={leftDisabled ? ArrowLeftDisabled : ArrowLeft}
                        disabled={leftDisabled}
                    />
                </ArrowButton> : null}
                {visibleTiles}
                {!isMobile ? 
                <ArrowButton 
                    onClick={() => {setIndex(index+1)}} 
                    disabled={rightDisabled}
                    right={10}
                    left={0}
                >
                    <ArrowIcon 
                        src={rightDisabled ? ArrowRightDisabled : ArrowRight}
                        disabled={rightDisabled}
                    />
                </ArrowButton> : null}
            </PastEventsContainer>
        </SectionContainer>
    )
}

const PastEventTile = (props: {
    title: string;
    image: string;
    width: number;
}) => {
    const { isMobile } = useContext(SiteContext);
    const imageURL = useImage('data/images/rush/'+props.image+'.jpeg')
    const height = props.width/PAST_ASPECT;

    return (
        <PastTileContainer isMobile={isMobile}>
            <PastTileImage src={imageURL} width={props.width} height={height} isMobile={isMobile}/>
            <Text size={isMobile ? theme.fontM : theme.fontML} style={styles.event}>{props.title}</Text>
        </PastTileContainer>
    )
}

const SectionContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    flex: 1;
    margin-bottom: ${props => props.margin}px;
    padding: 0px 20px;
`;

const PastEventsContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.isMobile ? 'column' : 'row'};
    flex: 1;
    margin-top: 10px;
`;

const PastTileContainer = styled.div`
    diplay: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    background: ${theme.lightBackground};
    border-radius: 10px;
    margin: ${props => props.isMobile ? '20px 0px' : '0px 10px'};
`;

const PastTileImage = styled.img`
    width: ${props => props.isMobile ? '85vw' : props.width + 'px'};
    height: ${props => props.isMobile ? '63vw' : props.height + 'px'};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

const ArrowButton = styled.button`
    background: transparent;
    border: none;
    padding: 0px;
    margin-right: ${props => props.right}px;
    margin-left: ${props => props.left}px;
    align-items: flex-start;
`;

const ArrowIcon = styled.img`
    width: 65px;
    cursor: ${props => props.disabled ? 'auto' : 'pointer'};
`;

export default PastEvents;