import officers from '../raw/officers.json'
import brotherFetcher from './brotherFetcher';
import { Officer } from 'data/types';

const officersFetcher = (): Officer[] => {
    const officerObjs = officers.order.map(pos => {
        const brotherId = officers[pos].brother;
        const email = officers[pos].email;
        const brother = brotherFetcher(brotherId);
        return {
            id: brotherId,
            name: brother.name,
            course: brother.course,
            hometown: brother.hometown,
            interests: brother.interests,
            positions: brother.positions,
            image: brother.image,
            email: email,
            position: pos
        }
    })
    return officerObjs;
}

export default officersFetcher;