import React, { useEffect, useContext } from 'react';
import { HeaderThemes } from 'styles/theme';
import _ from 'lodash'
import styled from 'styled-components'
import officersFetcher from 'data/datafetchers/officersFetcher'
import BackgroundImage from 'data/images/content/2027-founding-fathers.jpg'
import Header from 'components/Header'
import OfficerTile from 'components/OfficerTile'
import { useWindowSize } from 'helpers/hooks'
import { Text } from 'components/common'
import { Helmet } from 'react-helmet-async'
import theme from 'styles/theme'
import SiteContext from 'helpers/SiteContext'

const SINGLE_COLUMN_CUTOFF = 880;

const styles = {
    title: {
        fontWeight: theme.fontBold,
        color: theme.dark,
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 20,
        letterSpacing: 0.5,
    }
}

const Contact = () => {
    const { isMobile, setSite } = useContext(SiteContext)

    useEffect(() => {
        setSite(HeaderThemes.LIGHT, true);
    }, [setSite])

    const officers = officersFetcher();
    const windowSize = useWindowSize();
    const singleCol = windowSize.width < SINGLE_COLUMN_CUTOFF;

    const officerGroups = _.chunk(officers, singleCol ? 1 : 2);
    const rows = officerGroups.map(row => {
        const officerTiles = row.map(o => {
            return <OfficerTile key={o.id} singleCol={singleCol} officer={o}/>
        })
        const key = row.map(r => r.id).reduce((i, j) => i + j);
        return (
            <div key={key}>
                <Divider/>
                <OfficerRowContainer>
                    {officerTiles}
                </OfficerRowContainer>
            </div>
        )
    })

    return (
        <>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact the officers of Chi Phi" />
            </Helmet>
            <Header 
                title="contact us"
                image={BackgroundImage}
                imagePos='center center'
                cover={true}
            />
            <ContentContainer>
                <Text style={styles.title} size={isMobile ? theme.fontMS : theme.fontL}>Feel free to direct questions toward relevant officers.</Text>
                {rows}
            </ContentContainer>
        </>
    )
}

const ContentContainer = styled.div`
    margin-top: 45vh;
    margin-bottom: 60px;
    display: flex;
    padding: 0px 90px;
    @media (max-width: 760px) {
        padding: 0px 30px;
    }
    flex-direction: column;
`;

const OfficerRowContainer = styled.div`
    display: flex;
    flex: 1;
`;

const Divider = styled.div`
    border-bottom: 2px solid ${theme.lightGrey};
    height: 2px;
    padding: 10px 20px;
`;

export default Contact