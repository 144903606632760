import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Text } from '../components/common';
import { useWindowSize } from '../helpers/hooks';
import styled from 'styled-components';
import theme from '../styles/theme';
import CPLogo from '../data/images/content/chiphilogo.png'
import CPMenu from '../data/images/content/menu.png'
import CPMenuDark from 'data/images/content/menuDark.png'
import { PARTY_PAGE, RUSH_PAGE, RUSH_START } from '../data/environment'
import { useContext } from 'react';
import SiteContext from 'helpers/SiteContext';
import { HeaderThemes } from '../styles/theme';

const getStyles = (isMobile, textColor) => {
    return {
        item: {
            padding: isMobile ? 8 : 15,
            alignContent: 'center',
            textDecoration: 'none',
            color: textColor,
            fontWeight: theme.fontRegular,
            textTransform: 'uppercase',
            marginLeft: isMobile ? '20px' : 'auto',
            width: isMobile ? '100%' : 'auto',
        },
        activeItem: {
            fontWeight: theme.fontBold,
            textTransform: 'uppercase',
        },
        stackItem: {
            padding: 15,
            alignContent: 'left',
            textDecoration: 'none',
            color: textColor,
            fontWeight: theme.fontBold,
            textTransform: 'uppercase',
            display: 'flex',
        }
    }
}

const NavBar = () => {
    const windowSize = useWindowSize();
    const useHamburger = windowSize.width < 1150;
    const { headerColor } = useContext(SiteContext)
    const textColor = headerColor === HeaderThemes.LIGHT ? theme.light : theme.dark;
    const backgroundColor = headerColor === HeaderThemes.LIGHT ? theme.dark : theme.light;
    const menuImage = headerColor === HeaderThemes.LIGHT ? CPMenu : CPMenuDark;
    const styles = getStyles(useHamburger, textColor);
    const [itemsVisible, setItemsVisible] = useState(false);

    return (
        <>
            <Container style={itemsVisible ? {position: 'relative', backgroundColor: backgroundColor} : null}>
                <LeftContainer>
                    <NavLink 
                        style={styles.stackItem} 
                        to="/"
                        onClick={() => setItemsVisible(false)}
                    >
                        <Logo src={CPLogo} />
                        <Text size={theme.fontML}>chi phi</Text>
                    </NavLink>
                </LeftContainer>
                {useHamburger ? 
                    <Menu src={menuImage} onClick={() => setItemsVisible(!itemsVisible)} color={backgroundColor}/> : 
                    <NavBarItems styles={styles} onClick={setItemsVisible} color={backgroundColor}/>
                }
            </Container>
            {itemsVisible ? <NavBarItems styles={styles} onClick={setItemsVisible} color={backgroundColor}/> : null}
        </>
    )
}

const NavBarItems = (props: {
    styles: any
    color: string
    onClick: (setItems) => void;
}) => {    
    return (
        <RightContainer color={props.color}>
            <NavLink 
                style={props.styles.item} 
                to="/about" 
                activeStyle={props.styles.activeItem}
                onClick={() => props.onClick(false)}
            >
                <Text size={theme.fontMSS}>about</Text>
            </NavLink>
            <NavLink 
                style={props.styles.item} 
                to="/brothers" 
                activeStyle={props.styles.activeItem}
                onClick={() => props.onClick(false)}
            >
                <Text size={theme.fontMSS}>brothers</Text>
            </NavLink>
            <NavLink 
                style={props.styles.item} 
                to="/wherewework"
                activeStyle={props.styles.activeItem}
                onClick={() => props.onClick(false)}
            >
                <Text size={theme.fontMSS}>where we work</Text>
            </NavLink>
            <NavLink 
                style={props.styles.item} 
                to="/contact" 
                activeStyle={props.styles.activeItem}
                onClick={() => props.onClick(false)}
            >
                <Text size={theme.fontMSS}>contact us</Text>
            </NavLink>
            {RUSH_PAGE ?
            <NavLink 
                style={props.styles.item} 
                to="/rush" 
                activeStyle={props.styles.activeItem}
                onClick={() => props.onClick(false)}
            >
                <Text size={theme.fontMSS}>rush</Text>
            </NavLink>: null}
            {PARTY_PAGE && RUSH_START < Date.now() ? 
            <NavLink 
                style={props.styles.item} 
                to="/party" 
                activeStyle={props.styles.activeItem}
                onClick={() => props.onClick(false)}
            >
                <Text size={theme.fontMSS}>party</Text>
            </NavLink>: null}
        </RightContainer>
    )
}

const Container = styled.div`
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    z-index: 2;
    flex: 1;
`;

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
    flex: 1;
    display: flex;
    z-index: 2;
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 1150px) {
        flex-direction: column;
        background-color: ${props => props.color};
        margin-right: 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        position: absolute; 
        top: 70px;
        width: 100vw;
    }
    margin-right: 20px;
    margin-left: auto;
    z-index: 2;
`;

const Logo = styled.img`
    height: 45px;
    padding-bottom: 8px;
    padding-right: 8px;
    cursor: pointer;
`;

const Menu = styled.img`
    height: 25px;
    padding-bottom: 8px;
    padding-right: 15px;
    cursor: pointer;
    z-index: 2;
`;

export default NavBar;