import Classes from '../raw/classes.json';
import { Brother, Class } from '../types';
import brotherFetcher from './brotherFetcher';

const classFetcher = (year: string): Class => {
    const brothers: Brother[] = Classes[year].map((bro: string) => {
        return brotherFetcher(bro);
    });
    return {year: year, brothers: brothers }
}

export default classFetcher;