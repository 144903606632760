import React, { useContext } from "react";
import styled from 'styled-components'
import Map from "./Map";
import MapPin from 'data/images/content/mapPin.png'
import SiteContext from "helpers/SiteContext";
import { Text } from "components/common";
import theme from "styles/theme";

const styles = {
    title: {
        fontWeight: theme.fontMedium,
        color: theme.dark,
    },
    subtitle: {
        fontWeight: theme.fontRegular,
        color: theme.dark,
        textDecoration: 'none',
    },
    text: {
        fontWeight: theme.fontLight,
        color: theme.dark,
        marginTop: 15,
    }
}

const Directions = () => {
    const { isMobile } = useContext(SiteContext);

    return (
        <DirectionsContainer isMobile={isMobile}>
            <DirectionsStack isMobile={isMobile}>
                <Text style={styles.title} size={isMobile ? theme.fontML : theme.fontXL}>Getting to Chi Phi</Text>
                <Address isMobile={isMobile}>
                    <Pin src={MapPin} isMobile={isMobile}/>
                    <a href='https://www.google.com/maps/place/32+Hereford+St,+Boston,+MA+02115/@42.3501574,-71.0884104,622m' 
                        target='_blank'
                        className='hoverLink'
                        rel='noreferrer'
                    >
                        <Text style={styles.subtitle} size={isMobile ? theme.fontM : theme.fontML}>32 Hereford St</Text>
                    </a>
                </Address>
                <Text style={styles.text} size={theme.fontMSS}>Chi Phi is located in the beautiful Back Bay neighborhood on the corner of Commonwealth and Hereford. From campus, the quickest and easiest way to get to Chi Phi is to walk on Mass Ave across the Harvard Bridge, take a left of Commonwealth, then walk straight until you see our house.</Text>
            </DirectionsStack>
            <Map/>
        </DirectionsContainer>
    )
}

const DirectionsContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.isMobile ? 'column' : 'row'};
    flex: 1;
    margin: ${props => props.isMobile ? '20px' : '50px'} 0px;
`;

const DirectionsStack = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: ${props => props.isMobile ? '0px' : '30px'};
    width: ${props => props.isMobile ? '100%' : '50%'};
`;

const Address = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-top: ${props => props.isMobile ? '10px' : '0px'};
`;

const Pin = styled.img`
    height: ${props => props.isMobile ? '23px' : '30px'};
    width: ${props => props.isMobile ? '23px' : '30px'};
    margin: ${props => props.isMobile ? '6px' : '7px'} 10px 0px 0px;
`;

export default Directions;