import '../index.css'

 const theme = {
    white: '#ffffff',
    black: '#000000',
    dark: '#161616',
    light: '#f8f8f8',
    lightBackground:'#efefef',
    darkBackground: '#313131',
    grey: '#3d3d3d',
    lightGrey: '#b3b3b3',
    linkBlue: '#0645AD',
    fontHuge: '128px',
    fontXXXXL: '90px',
    fontXXXL: '72px',
    fontXXL: '60px',
    fontXL: '48px',
    fontL: '36px',
    fontML: '30px',
    fontM: '24px',
    fontMS: '20px',
    fontMSS: '18px',
    fontS: '16px',
    fontXS: '14px',
    fontXXS: '12px',
    fontLight: 200,
    fontRegular: 400,
    fontMedium: 600,
    fontBold: 700,
}

export enum HeaderThemes {
    DARK = 'dark',
    LIGHT = 'light'
}

export default theme;