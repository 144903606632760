import React from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <Container>
            <Link style={styles.link} to="/private">Brothers Only</Link>
        </Container>
    )
}
export default Footer

const styles = {
    link: {
        fontWeight: theme.fontBold,
        color: theme.dark,
        textDecoration: "none",
        paddingLeft: 15,
        paddingRight: 15,
    }
}

const Container = styled.div`
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex: 0;
    background-color: ${theme.darkBackground};
`;